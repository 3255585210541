<template>
  <div class="buyer-verifications-table">
    <buyer-verifications-filter
      :filter.sync="pager.filter"
      @change="pager.page = 1"
    />

    <buyer-verifications-query :pager="pager">
      <template slot-scope="{ isLoading, data: paginator }">
        <div class="relative">
          <el-table
            :data="paginator ? paginator.data : []"
            border
            class="w-full margin-top-xxl el-table-slim"
            :default-sort="{ prop: 'start_date', order: 'descending' }"
            @sort-change="sortColumns"
          >
            <template slot="empty">
              <div v-if="!isLoading && paginator" class="pad-lg">
                {{ emptyFilterMessage }}
              </div>
              <loading-page v-else :failed="!isLoading">
                <template slot="failed-text"
                  >There was a problem loading your orders. Please try again
                  later.
                </template>
              </loading-page>
            </template>

            <el-table-column
              label="Date"
              align="center"
              width="100"
              prop="start_date"
              sortable="custom"
              sort-by="start_date"
            >
              <template slot-scope="{ row }">
                <ad-date-column :ad="row" />
              </template>
            </el-table-column>

            <el-table-column label="IO" align="center" width="100">
              <template slot-scope="{ row }">
                <io-item
                  :order="row.order"
                  :campaign="row.campaign"
                  show-campaign-ref
                  download
                />
              </template>
            </el-table-column>

            <el-table-column
              label="Publication"
              sortable="custom"
              sort-by="buyer.buyer_company"
            >
              <template slot-scope="{ row }">
                <div>{{ row.campus.name }}</div>
                <div>{{ row.supplier.name }}</div>
              </template>
            </el-table-column>

            <el-table-column label="Ad Details">
              <template slot-scope="{ row }">
                <ad-column :ad="row" />
              </template>
            </el-table-column>

            <el-table-column label="Verification" align="center" width="220">
              <template slot-scope="{ row }">
                <buyer-verification-column :ad="row" />
              </template>
            </el-table-column>
          </el-table>

          <pagination
            v-if="paginator && paginator.paginatorInfo.total > 0"
            :info="paginator.paginatorInfo"
            :pager.sync="pager"
            class="margin-top"
          />

          <loading-page v-if="paginator && isLoading" class="is-low-opacity" />
        </div>
      </template>
    </buyer-verifications-query>
  </div>
</template>

<script>
import { sortFilter } from '@/utils/sortFilter';
import { filePdf } from '@/vendor/icons';
import { date as formatDate } from '@/utils/filters';

import {
  AdColumn,
  AdDateColumn,
  BuyerVerificationColumn
} from '@/components/Core/Table/Columns';
import BuyerVerificationsFilter from './BuyerVerificationsFilter';
import IoItem from '@/components/Order/IoItem';
import LoadingPage from '@/components/Core/Loading/LoadingPage';
import Pagination from '@/components/Core/Table/Pagination';
import { BuyerVerificationsQuery } from '@/components/Queries';

export default {
  components: {
    IoItem,
    AdColumn,
    AdDateColumn,
    BuyerVerificationColumn,
    BuyerVerificationsFilter,
    BuyerVerificationsQuery,
    LoadingPage,
    Pagination
  },

  data() {
    return {
      pager: {
        perPage: 10,
        page: 1,
        sort: [{ column: 'start_date', order: 'descending' }],
        filter: {
          'creative.id': { null: false }
        }
      },

      // Icons
      filePdf
    };
  },

  computed: {
    emptyFilterMessage() {
      if (this.pager.filter.start_date) {
        let startDate = this.pager.filter.start_date['>='];
        let endDate = this.pager.filter.start_date['<='];

        let str =
          "You don't have any ads running between " + formatDate(startDate);

        if (endDate) {
          str += ' and ' + formatDate(endDate);
        } else {
          str += ' and the end of time';
        }

        return str;
      } else {
        return "You don't have any ads :(";
      }
    }
  },

  methods: {
    ...sortFilter
  }
};
</script>
