<template>
  <div id="page-buyer-verifications" class="page-content text-dark-gray">
    <the-page-header>
      <template #title>
        Verifications
      </template>
      <template #subtitle>
        See how your ads turned out. Verification images uploaded by the
        publisher appear below.
      </template>
    </the-page-header>

    <buyer-verifications-table />
  </div>
</template>

<script>
import BuyerVerificationsTable from '@/modules/Buyer/Verifications/BuyerVerificationsTable';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';

export default {
  components: {
    BuyerVerificationsTable,
    ThePageHeader
  }
};
</script>
