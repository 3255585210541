<template>
  <tree-select
    v-model="selectedProducts"
    :limit="1"
    :limit-text="c => `+${c}`"
    :clear-on-select="true"
    multiple
    placeholder="All Products"
    :options="products"
    class="is-width-xm product-tree-select"
    append-to-body
    @input="changeProduct"
  >
    <label
      slot="option-label"
      slot-scope="{
        node,
        shouldShowCount,
        count,
        labelClassName,
        countClassName
      }"
      :class="labelClassName"
      class="text-dark-gray text-md"
    >
      {{ node.label }}
      <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
    </label>
  </tree-select>
</template>

<script>
import TreeSelect from '@riophae/vue-treeselect';

import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: {
    TreeSelect
  },
  props: {
    products: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      selectedProducts: []
    };
  },

  methods: {
    changeProduct() {
      let typeNames = [
        'medium_name',
        'property_name',
        'collection_name',
        'product_name',
        'product_variant_name'
      ];

      let productQuery = [];

      for (let product of this.selectedProducts) {
        let names = product.split(';');

        let query = [];

        for (let typeIndex in typeNames) {
          let typeName = typeNames[typeIndex];

          if (names[typeIndex]) {
            query.push(`${typeName} = '${names[typeIndex]}'`);
          } else {
            break;
          }
        }

        productQuery.push(query.join(' AND '));
      }

      if (productQuery.length > 0) {
        this.$emit('change', { raw: '(' + productQuery.join(') OR (') + ')' });
      } else {
        this.$emit('change', undefined);
      }
    }
  }
};
</script>
