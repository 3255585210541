var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buyer-verifications-table"},[_c('buyer-verifications-filter',{attrs:{"filter":_vm.pager.filter},on:{"update:filter":function($event){return _vm.$set(_vm.pager, "filter", $event)},"change":function($event){_vm.pager.page = 1}}}),_c('buyer-verifications-query',{attrs:{"pager":_vm.pager},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var paginator = ref.data;
return [_c('div',{staticClass:"relative"},[_c('el-table',{staticClass:"w-full margin-top-xxl el-table-slim",attrs:{"data":paginator ? paginator.data : [],"border":"","default-sort":{ prop: 'start_date', order: 'descending' }},on:{"sort-change":_vm.sortColumns}},[_c('template',{slot:"empty"},[(!isLoading && paginator)?_c('div',{staticClass:"pad-lg"},[_vm._v(" "+_vm._s(_vm.emptyFilterMessage)+" ")]):_c('loading-page',{attrs:{"failed":!isLoading}},[_c('template',{slot:"failed-text"},[_vm._v("There was a problem loading your orders. Please try again later. ")])],2)],1),_c('el-table-column',{attrs:{"label":"Date","align":"center","width":"100","prop":"start_date","sortable":"custom","sort-by":"start_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ad-date-column',{attrs:{"ad":row}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"IO","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('io-item',{attrs:{"order":row.order,"campaign":row.campaign,"show-campaign-ref":"","download":""}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Publication","sortable":"custom","sort-by":"buyer.buyer_company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.campus.name))]),_c('div',[_vm._v(_vm._s(row.supplier.name))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Ad Details"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ad-column',{attrs:{"ad":row}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Verification","align":"center","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('buyer-verification-column',{attrs:{"ad":row}})]}}],null,true)})],2),(paginator && paginator.paginatorInfo.total > 0)?_c('pagination',{staticClass:"margin-top",attrs:{"info":paginator.paginatorInfo,"pager":_vm.pager},on:{"update:pager":function($event){_vm.pager=$event}}}):_vm._e(),(paginator && isLoading)?_c('loading-page',{staticClass:"is-low-opacity"}):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }