<template>
  <div class="buyer-ads-filter filter-bar margin-top-xxl">
    <div class="flex flex-wrap">
      <div class="block p-3 flex-1 is-narrow">
        <div class="is-width-xl">
          <el-date-picker
            v-model="runDate"
            type="daterange"
            range-separator="-"
            start-placeholder="Start date"
            end-placeholder="End date"
            format="MM/dd/yyyy"
            class="date-range-input"
            :picker-options="pickerOptions"
            @change="changeRunDate"
          />
        </div>
      </div>
      <div class="block p-3 flex-1 text-right overflow-scroll-x">
        <buyer-ads-filter-data-query>
          <template slot-scope="{ data: buyer }">
            <div v-if="buyer" class="flex flex-wrap justify-end">
              <div class="block p-0"></div>
              <div class="block p-3">
                <product-tree-select
                  :products="buyer.purchased_products"
                  @change="change({ 'orderProduct.medium_name': $event })"
                />
              </div>
              <div class="block p-3">
                <el-select
                  v-model="publications"
                  placeholder="All Publications"
                  multiple
                  filterable
                  collapse-tags
                  class="is-width-md whitespace-no-wrap"
                  @input="change({ 'supplier.name': $event })"
                >
                  <el-option
                    v-for="supplier in buyer.suppliers"
                    :key="`buyer-${supplier.id}`"
                    :label="supplier.name"
                    :value="supplier.name"
                  />
                </el-select>
              </div>

              <div class="block p-3">
                <export-button :is-exporting="isExporting" @click="exportAds" />
              </div>
            </div>
          </template>
        </buyer-ads-filter-data-query>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { sortFilter } from '@/utils/sortFilter';
import { download } from '@/utils/helpers';
import { PICKER_SHORTCUTS } from '@/utils/date';
import { shareSquareO } from '@/vendor/icons';

import { BuyerAdsFilterDataQuery } from '@/components/Queries';
import ExportButton from '@/components/Core/Table/ExportButton';
import ProductTreeSelect from '@/components/Core/ProductTreeSelect';

export default {
  components: {
    BuyerAdsFilterDataQuery,
    ExportButton,
    ProductTreeSelect
  },
  props: {
    filter: { type: Object, default: null }
  },

  data() {
    let minDate =
      (this.filter.start_date && this.filter.start_date['>=']) || '';
    let maxDate =
      (this.filter.start_date && this.filter.start_date['<=']) || '';

    return {
      runDate: [minDate, maxDate],
      publications: [],
      isExporting: false,
      pickerOptions: {
        shortcuts: PICKER_SHORTCUTS.ALL
      },

      // Icons
      shareSquareO
    };
  },

  methods: {
    ...sortFilter,

    change(change) {
      let filter = { ...this.filter, ...change };
      this.$emit('update:filter', filter);
      this.$emit('change', filter);
    },

    changeRunDate() {
      this.change({
        start_date: this.filterDateBetween(this.runDate[0], this.runDate[1])
      });
    },

    exportAds() {
      let params = {
        filter: this.filter
      };

      this.isExporting = true;

      axios
        .post(
          '/buyer/' +
            this.$store.getters['auth/buyerId'] +
            '/verifications/export',
          params
        )
        .then(({ data }) => {
          this.isExporting = false;

          if (data.success) {
            download(data.csv, data.name);
          }
        });
    }
  }
};
</script>
